.external-client-container {
  width: 100% !important;
}

*:disabled {
  -webkit-text-fill-color: #000 !important;
}

.Mui-disabled {
  -webkit-text-fill-color: #000 !important;
}

.dropzone-report {
  display: flex;
  margin-bottom: 1.5%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
