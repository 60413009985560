/* #filter {
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

#filter.visible {
  opacity: 1;
}
 */
#filter-buttons {
  display: flex;
  /* position: fixed; */
  justify-content: flex-end;
}

#filter-buttons button {
  margin: 0 5px;
}

.MuiDivider-root {
  margin-top: 2vh !important;
  margin-bottom: 2vh !important;
}
