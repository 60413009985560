.Mui-selected {
  background-color: #207868 !important;
}

.Mui-selected span {
  font-weight: bold;
  color: #ffffff !important;
}

.Mui-selected svg {
  fill: #ffffff !important;
}
